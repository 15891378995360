import React, { useState }  from "react"
import axios from "axios";
import ReactMarkdown from "react-markdown"
import {graphql, Link, useStaticQuery} from 'gatsby'


const query = graphql`
{
  strapiContactpage {
    contactdata {
      icon {
        localFile {
          url
        }
      }
      content
      subject
    }
    headline
    text
    image{
        localFile {
          url
        }
      }
    successMessage
    form {
      phone_placeholder
      name_placeholder
      message_placeholder
      e_mail_placeholder
      button_text
      checkbox_text
      checkbox_linktext
      legal {
        slug
      }
    }
  }
}
`



const ContactForm = () => {
    const data = useStaticQuery(query)

    const {
        strapiContactpage: {headline, text, image, successMessage,
            form: { phone_placeholder, name_placeholder, message_placeholder, e_mail_placeholder, button_text, checkbox_text, checkbox_linktext, legal: {slug} }}} = data


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [checkbox, setCheckbox] = useState(true)


    const onSubmit = e => {
        e.preventDefault();

        axios
            .post(`${process.env.API_URL}/contacts`, {
                name: name,
                email: email,
                phone: phone,
                message: message,
                checkbox: checkbox,

            })
            .then(res => {

                if(res.status===200) {
                    //reload posts
                    setName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                    setCheckbox('')

                    document.getElementById('contactForm').style.display = 'none'
                    document.getElementById('successMessage').style.display = 'block'
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <h2>{headline}</h2>
                    <ReactMarkdown children={text} />
                </div>

                <div className="row">


                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name={'name'} onChange={event => setName(event.target.value)} value={name} className="form-control" required placeholder={name_placeholder} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" onChange={event => setEmail(event.target.value)} value={email} className="form-control" required placeholder={e_mail_placeholder}  />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone" onChange={event => setPhone(event.target.value)} value={phone} className="form-control" required placeholder={phone_placeholder}  />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" value={message} onChange={event => setMessage(event.target.value)} cols="30" rows="6" required placeholder={message_placeholder}  />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group checkbox-group">
                                            <input type={'checkbox'} value={checkbox} name="checkbox" className="checkbox" required />
                                            <p>{checkbox_text}<Link to={`/${slug}`}>{checkbox_linktext}</Link></p>
                                        </div>
                                    </div>


                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn" >
                                            <i className="flaticon-tick"></i>
                                            {button_text}<span></span>
                                        </button>
                                    </div>
                                </div>

                            </form>
                            <div id={'successMessage'} className='successMessage'>
                                <ReactMarkdown children={successMessage} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={image.localFile.url} alt={image.alternativeText} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm
